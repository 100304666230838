import { Form, Select } from 'antd'
import HasAccess from 'core/HasAccess'
import React from 'react'
import { useSelector } from 'react-redux'

const { Option } = Select

const Reruns = ({ defaultReRuns, setReRuns }) => {
  const permissions = useSelector(({ user: { permissions } }) => permissions)

  if (!HasAccess.run(permissions, 'lpNewFeature')) return null

  const handleChange = (value) => {
    setReRuns(value)
  }

  return (
    <Form.Item label="Re-runs">
      <Select
        defaultValue={defaultReRuns}
        onChange={handleChange}
        placeholder="Select"
      >
        <Option value="with">Including</Option>
        <Option value="without">Excluding</Option>
      </Select>
    </Form.Item>
  )
}

export default Reruns
