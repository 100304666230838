export default [
  {
    field: 'age',
    title: 'Age',
    ordering: ['< 25 years', '25 to 29 years', '30 to 34 years', '35 to 44 years', '45 to 59 years', '≥ 60 years'],
    type: 'number',
    fieldForFilter: 'ageRaw',
  },
  {
    field: 'region',
    fieldForFilter: 'region',
    title: 'Region',
    type: 'string',
    values: [
      { value: 'NT', label: 'North East' },
      { value: 'YH', label: 'Yorkshire & Humberside' },
      { value: 'EM', label: 'East Midlands' },
      { value: 'EA', label: 'East of England' },
      { value: 'GL', label: 'Greater London' },
      { value: 'SE', label: 'South East' },
      { value: 'SW', label: 'South West' },
      { value: 'WM', label: 'West Midlands' },
      { value: 'NW', label: 'North West' },
      { value: 'WA', label: 'Wales' },
      { value: 'SC', label: 'Scotland' },
      { value: 'NI', label: 'Northern Ireland' },
    ],
  },
  {
    field: 'ownership',
    fieldForFilter: 'ownership',
    title: 'Ownership',
    type: 'string',
    values: [
      { value: 'standard', label: 'Standard' },
      { value: 'helpbuy_equity_share', label: 'Help to buy equity share' },
      { value: 'shared_ownership', label: 'Shared' },
    ],
  },
  {
    field: 'employmentStatus',
    fieldForFilter: 'employmentStatus',
    title: 'Employment type',
    intendedFor: 'residential',
    type: 'string',
    values: [
      { label: 'Employed' },
      { label: 'Contractor' },
      { label: 'Self Employment' },
      { label: 'Director less 20pc' },
      { label: 'Director more 20pc' },
      { label: 'Retired' },
      { label: 'Unemployed' },
      { label: 'Other' },
    ],
  },
  {
    field: 'labeledReason',
    fieldForFilter: 'labeledReason',
    title: 'Reason for mortgage',
    type: 'string',
    values: [
      { label: 'First Time Buyer', intendedFor: 'residential' },
      { label: 'New Home', intendedFor: 'residential' },
      { label: 'Additional Property', intendedFor: 'residential' },
      { label: 'Remortgage', intendedFor: 'residential' },
      { label: 'Purchase', intendedFor: 'buy_to_let' },
      { label: 'Remortgage Improvement', intendedFor: 'buy_to_let' },
      { label: 'Remortgage Capital Raising', intendedFor: 'buy_to_let' },
      { label: 'Remortgage Like for Like', intendedFor: 'buy_to_let' },
      { label: 'Remortgage Debt Consolidation', intendedFor: 'buy_to_let' },
      { label: 'Other' },
    ],
  },
  {
    field: 'loanPurchase',
    fieldForFilter: 'loanPurchaseRaw',
    title: 'Property value',
    ordering: ['< £100k', '£100-200k', '£200-300k', '£300-500k', '£500-700k', '£700-1M', '≥ £1M'],
    type: 'number',
  },
  {
    field: 'loanBorrowing',
    fieldForFilter: 'loanBorrowingRaw',
    title: 'Loan requested',
    ordering: ['< £100k', '£100-200k', '£200-300k', '£300-500k', '≥ £500k'],
    type: 'number',
  },
  {
    field: 'lti',
    fieldForFilter: 'ltiRaw',
    title: 'LTI',
    ordering: ['< 3', '3-4', '4-4.5', '4.5-5', '≥ 5'],
    intendedFor: 'residential',
    type: 'number',
  },
  {
    field: 'ltv',
    fieldForFilter: 'ltvRaw',
    title: 'LTV',
    ordering: ['< 50%', '50-60%', '60-70%', '70-80%', '80-85%', '85-90%', '90-95%', '≥ 95%'],
    type: 'number',
  },
  {
    field: 'totalAnnualIncome',
    fieldForFilter: 'totalAnnualIncomeRaw',
    title: 'Income',
    ordering: ['< £20k', '£20-35k', '£35-50k', '£50-75k', '≥ £75k'],
    intendedFor: 'residential',
    type: 'number',
  },
  {
    field: 'totalGuaranteedIncome',
    fieldForFilter: 'totalGuaranteedIncomeRaw',
    title: 'Income (bonus)',
    ordering: ['£0', '£1-10k', '£10-20k', '£20-30k', '£30-40k', '£40-50k', '£50-60k', '≥ £70k'],
    intendedFor: 'residential',
    type: 'number',
  },
  {
    field: 'totalNonguaranteedIncome',
    fieldForFilter: 'totalNonguaranteedIncomeRaw',
    title: 'Income (variable)',
    ordering: ['£0', '£1-10k', '£10-20k', '£20-30k', '£30-40k', '£40-50k', '£50-60k', '≥ £60k'],
    intendedFor: 'residential',
    type: 'number',
  },
  {
    field: 'backgroundProperty',
    fieldForFilter: 'backgroundPropertyRaw',
    title: 'Background property',
    ordering: ['£0', '£1-500', '£500-1000', '£1000-1500', '£1500-2000', '≥ £2000'],
    intendedFor: 'residential',
    type: 'number',
  },
  {
    field: 'interestOnly',
    fieldForFilter: 'interestOnlyRaw',
    title: 'Interest Only',
    ordering: ['£0', '£1-10k', '£10-20k', '£20-30k', '£30-40k', '≥ £40k'],
    type: 'number',
  },
  {
    field: 'hmo',
    fieldForFilter: 'hmo',
    title: 'HMO',
    ordering: ['Yes', 'No/Not supplied'],
    intendedFor: 'buy_to_let',
    type: 'string',
    values: [
      { label: 'Yes' },
      { label: 'No/Not supplied' },
    ],
  },
  {
    field: 'mortgageTypeFixed',
    fieldForFilter: 'mortgageTypeFixed',
    title: 'Type Fixed',
    ordering: ['Yes', 'No'],
    type: 'string',
    values: [
      { label: 'Yes' },
      { label: 'No' },
    ],
  },
  {
    field: 'mortgageTypeTerm5Year',
    fieldForFilter: 'mortgageTypeTerm5Year',
    title: 'Term 5 year',
    ordering: ['Yes', 'No'],
    type: 'string',
    values: [
      { label: 'Yes' },
      { label: 'No' },
    ],
  },
  {
    field: 'mortgageTypeTerm2Year',
    fieldForFilter: 'mortgageTypeTerm2Year',
    title: 'Term 2 year',
    ordering: ['Yes', 'No'],
    type: 'string',
    values: [
      { label: 'Yes' },
      { label: 'No' },
    ],
  },
  {
    field: 'holidayLet',
    fieldForFilter: 'holidayLet',
    title: 'Holiday Let',
    ordering: ['Yes', 'No/Not supplied'],
    intendedFor: 'buy_to_let',
    type: 'string',
    values: [
      { label: 'Yes' },
      { label: 'No/Not supplied' },
    ],
  },
  {
    field: 'expat',
    fieldForFilter: 'expat',
    title: 'Expat',
    ordering: ['Yes', 'No/Not supplied'],
    intendedFor: 'buy_to_let',
    type: 'string',
    values: [
      { label: 'Yes' },
      { label: 'No/Not supplied' },
    ],
  },
  {
    field: 'topslicing',
    fieldForFilter: 'topslicing',
    title: 'Top slicing',
    ordering: ['Yes', 'No'],
    intendedFor: 'buy_to_let',
    type: 'string',
    values: [
      { label: 'Yes' },
      { label: 'No' },
    ],
  },
  {
    field: 'howManyResi',
    fieldForFilter: 'howManyResi',
    title: 'Mortgage Properties',
    ordering: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    intendedFor: 'buy_to_let',
    type: 'number',
  },
  {
    field: 'rentalAmountFrames',
    fieldForFilter: 'rentalAmount',
    title: 'Monthly Rental',
    ordering: ['< 500£', '£500-1000', '£1000-1500', '£1500-2000', '≥ 2000£'],
    intendedFor: 'buy_to_let',
    type: 'number',
  },
]
