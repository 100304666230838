export const CUBESERVER_INTEGRATION_HOSTNAME = 'cubeserver.mortgagehealth.co.uk'
export const CUBESERVER_STAGING_HOSTNAME = 'cubeserver.mortgagebroker.tools'
export const CUBESERVER_PRODUCTION_HOSTNAME = 'cubeserver.mortgagebrokertools.co.uk'

export const ANALYTICS_INTEGRATION_HOSTNAME = 'analytics.mortgagehealth.co.uk'
export const ANALYTICS_STAGING_HOSTNAME = 'analytics.mortgagebroker.tools'
export const ANALYTICS_PRODUCTION_HOSTNAME = 'analytics.mortgagebrokertools.co.uk'

export const MBT_INTEGRATION_HOSTNAME = 'mortgagehealth.co.uk'
export const MBT_STAGING_HOSTNAME = 'mortgagebroker.tools'
export const MBT_PRODUCTION_HOSTNAME = 'mortgagebrokertools.co.uk'

export const ELIXIR_BACKEND_INTEGRATION_HOSTNAME = 'api.mortgagehealth.co.uk'
export const ELIXIR_BACKEND_STAGING_HOSTNAME = 'api.mortgagebroker.tools'
export const ELIXIR_BACKEND_PRODUCTION_HOSTNAME = 'api.mortgagebrokertools.co.uk'

export const GATEWAY_INTEGRATION_HOSTNAME = 'gw.mortgagehealth.co.uk'
export const GATEWAY_STAGING_HOSTNAME = 'gw.mortgagebroker.tools'
export const GATEWAY_PRODUCTION_HOSTNAME = 'gw.mortgagebrokertools.co.uk'

export const CUBESERVER_GATEWAY_PATH = '/cubeserver'
