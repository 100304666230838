import React from 'react'
import _ from 'lodash'
import { getIn } from 'utils/immutable'
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import moment from 'moment'

const labelOverrides = {
  'Case Results Unaffordable Percentage': 'Unaffordable',
  'Case Results Unlendable Percentage': 'Your Will not lend',
  'Case Results Affordable Percentage': 'Affordable',
  'Cases Unaffordable Percentage': 'Unaffordable',
  'Cases Unlendable Percentage': 'Your Will not lend',
  'Cases Affordable Percentage': 'Affordable',
  'Cases Without Reruns Unaffordable Percentage': 'Unaffordable',
  'Cases Without Reruns Unlendable Percentage': 'Your Will not lend',
  'Cases Without Reruns Affordable Percentage': 'Affordable',
  'Case Results Without Reruns Unaffordable Percentage': 'Unaffordable',
  'Case Results Without Reruns Affordable Percentage': 'Affordable',
  'Case Results Without Reruns Unlendable Percentage': 'Your Will not lend',
}

const CartesianChart = ({
  data, children, ChartComponent, CustomTooltip, height, vizState,
}) => {
  const formatTooltip = (value, title) => {
    const formatter = getIn(vizState, ['yAxis', 'formatter'])
    const formattedValue = Math.round(value)
    const formattedTitle = labelOverrides[title] || title.split(',')[0]
    if (!formatter) return [formattedValue, formattedTitle]
    if (formatter === '%') return [`${formattedValue}%`, formattedTitle]
  }

  return (
    <ResponsiveContainer height={height} width="100%">
      <ChartComponent data={data} margin={{ left: -10 }}>
        <XAxis axisLine={false} dataKey="x" minTickGap={20} tickFormatter={(v) => xAxisFormatter(v, vizState)} />
        <YAxis
          axisLine={false}
          tickFormatter={(v) => yAxisFormatter(v, vizState)}
          tickLine={false}
          ticks={getIn(vizState, ['yAxis', 'ticks']) || []}
        />
        <CartesianGrid vertical={false} />
        { children }
        {!vizState.disabledLegend && <Legend formatter={(value) => labelOverrides[value] || value.split(',')[0]} />}
        {CustomTooltip
          ? <Tooltip content={<CustomTooltip />} />
          : (
            <Tooltip
              formatter={formatTooltip}
              labelFormatter={(v) => xAxisFormatter(v, vizState)}
              wrapperStyle={{ zIndex: 2 }}
            />
          )}
      </ChartComponent>
    </ResponsiveContainer>
  )
}

export const yAxisFormatter = (value, vizState) => {
  const formatter = _.get(vizState, ['yAxis', 'formatter'])
  if (!formatter) return numberFormatter(value)
  if (formatter === '%') return `${value}%`
}

export const numberFormatter = (item) => {
  if (item > 100000) return `${item / 1000000}M`
  if (item > 100) return `${item / 1000}k`
  return item
}

const DATE_FORMATS = {
  week: {
    short: '[w/c] DD MMM, YY',
    full: '[w/c] Do MMMM, YYYY',
  },
  month: {
    short: 'MMM, YY',
    full: 'MMM, YY',
  },
  year: {
    short: 'YYYY',
    full: 'YYYY',
  },
  default: {
    short: 'MMM, YY',
    full: 'MMM, YY',
  },
}

export const xAxisFormatter = (item, vizState, type = 'short') => {
  const period = getIn(vizState, ['query', 'timeDimensions', 0, 'granularity'])
  if (period) {
    return moment(item).format(DATE_FORMATS[period][type])
  }

  if (moment(item).isValid()) {
    return moment(item).format(DATE_FORMATS.default[type])
  }
  return item
}

export default CartesianChart
