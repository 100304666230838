import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import HasAccess from 'core/HasAccess'
import dimensions from 'dimensions'
import Utils from 'utils'
import { useSelector } from 'react-redux'
import CustomFilter from '../DashboardItem/options/CustomFilters/CustomFilter'

const getDefaultFilter = () => ({
  dimension: 'region',
  operator: 'equals',
  values: [],
  id: uuidv4(),
})

const CustomFilters = ({
  item,
  type,
  updatedCustomFilters,
  setUpdatedCustomFilters,
}) => {
  const permissions = useSelector(({ user: { permissions } }) => permissions)

  const getFilterDimensions = () => {
    const ttype = type || Utils.Cube.getType(item)
    return dimensions.filter((dim) => !dim.intendedFor || dim.intendedFor === ttype)
  }

  const handleAdd = () => {
    if (!HasAccess.run(permissions, 'lpMultiFilters')
     && !HasAccess.run(permissions, 'lpTwoFilters')
      && !HasAccess.run(permissions, 'lpThreeFilters')) return
    if (HasAccess.run(permissions, 'lpTwoFilters') && updatedCustomFilters.length > 2) return
    if (HasAccess.run(permissions, 'lpThreeFilters') && updatedCustomFilters.length > 3) return

    setUpdatedCustomFilters([...updatedCustomFilters, getDefaultFilter()])
  }

  const handleRemove = (filter) => {
    const newFilters = updatedCustomFilters.filter((f) => f.id !== filter.id)
    setUpdatedCustomFilters(newFilters)
  }
  const handleUpdate = (filter, attrs) => {
    const newFilters = updatedCustomFilters.map((f) => (f.id === filter.id ? { ...filter, ...attrs } : f))
    setUpdatedCustomFilters(newFilters)
  }

  const ttype = type || Utils.Cube.getType(item)

  return (
    <div>
      <div>Custom Filters</div>
      {updatedCustomFilters.map((filter, index) => (
        <CustomFilter
          key={index}
          index={index}
          size={updatedCustomFilters.length}
          filter={filter}
          type={ttype}
          onAdd={handleAdd}
          onRemove={handleRemove}
          onUpdate={handleUpdate}
          dimensions={getFilterDimensions()}
        />
      ))}
    </div>
  )
}

export default CustomFilters
