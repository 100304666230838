import React from 'react'
import styled from 'styled-components'

const CardHeaderWrapper = styled.h1`
  text-align: center;
  background-color: #166e75;
  padding: 10px;
  color: white;
  margin-bottom: 0;
  border-radius: 4px 4px 0 0;
`

const CardHeader = ({ title }) => (
  <CardHeaderWrapper>{title}</CardHeaderWrapper>
)

export default CardHeader
