import { Form, Select } from 'antd'
import React from 'react'

const { Option } = Select

const DEFAULT_OPTIONS = [
  { label: 'Year', value: 'year' },
  { label: 'Month', value: 'month' },
  { label: 'Week', value: 'week' },
]

const GroupingByTime = ({ defaultGroupingByTime, setGroupingByTime }) => {
  const handleChange = (value) => {
    setGroupingByTime(value)
  }

  const getOptions = () => DEFAULT_OPTIONS

  return (
    <Form.Item label="Grouping by Time">
      <Select
        defaultValue={defaultGroupingByTime}
        onChange={handleChange}
        placeholder="Select time"
      >
        {getOptions().map((option) => <Option key={option.value} value={option.value}>{option.label}</Option>)}
      </Select>
    </Form.Item>
  )
}

export default GroupingByTime
